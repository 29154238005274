export default {
  init() {
    $('.dropdown a').addClass('dropdown-toggle');
    $('.dropdown .sub-menu').addClass('dropdown-menu');
    $('.dropdown .sub-menu a').removeClass('dropdown-toggle');

    $('.navbar-toggler').click(function(){
      $(this).toggleClass('open');
      $('html').toggleClass('freeze');
      $('.navbar-collapse').removeClass('show');
      $('.navbar-collapse').toggleClass('collapse');
    });

    $('.read-more').click(function() {
      event.preventDefault();
      if($(this).text() == 'Read More'){
         $(this).text('Read Less');
         $(this).parent().find('p:not(.intro)').slideDown('slow');
       } else {
         $(this).text('Read More');
         $(this).parent().find('p:not(.intro)').slideUp('slow');
       }
    }); 

    $('.show-full').click(function() {
      event.preventDefault();
      if($(this).text() == 'See Full Posting & Apply'){
         $(this).text('Close Posting');
         $(this).parent().find('p').not(':first').slideDown('slow');
       } else {
         $(this).text('See Full Posting & Apply');
         $(this).parent().find('p').not(':first').slideUp('slow');
       }
    });

    $('.types article').click(function() {
      event.preventDefault();
      var id = $(this).attr('id');
      var x = $(window).scrollTop();
      if($('#units-'+id).hasClass('show')) {
        $(this).removeClass('active')
        $('#units-'+id).removeClass('show').slideUp('slow');
        $(window).scrollTop(x-400);
      } else {
        $('.types article').removeClass('active');
        $(this).addClass('active');
        $('.units.show').slideUp('slow');
        $('.units').removeClass('show');
        $('#units-'+id).addClass('show').slideDown('slow');
        $(window).scrollTop(x+400);
      }
    });

    $('.types article .btn').click(function() {
      event.preventDefault();
    });

    $('.slick-gallery').slick({
      autoplay: true,
      speed: 35000,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      infinite: true,
    });

    $('.slick-gallery').magnificPopup({
      delegate: '.slick-slide:not(.slick-cloned) a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
      },
    });

    $('.unit-slider').slick({
      autoplay: true,
      speed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      draggable: true,
      fade: true,      
      prevArrow: '<a href="#" class="prev slick-arrow"><i class="fas fa-chevron-left"></i></a>',
      nextArrow: '<a href="#" class="next slick-arrow"><i class="fas fa-chevron-right"></i></a>',
    });

    $('.unit-slider').magnificPopup({
      delegate: '.slick-slide .btn-primary',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
      },
    });

    $('.vacant aside .btn').magnificPopup({
      type: 'image',
    });

    var $slider = $('.test-slider');

    if ($slider.length) {
      var currentSlide;
      var slidesCount;
      var sliderCounter = $('.test-counter div');

      // eslint-disable-next-line
      var updateSliderCounter = function(slick, currentIndex) {
        currentSlide = slick.slickCurrentSlide() + 1;
        slidesCount = slick.slideCount;
        $(sliderCounter).text(currentSlide + '/' +slidesCount)
      };

      $slider.on('init', function(event, slick) {
        updateSliderCounter(slick);
      });

      $slider.on('afterChange', function(event, slick, currentSlide) {
        updateSliderCounter(slick, currentSlide);
      });

      $slider.slick({
        cssEase: 'linear',
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.test-counter .prev'),
        nextArrow: $('.test-counter .next'),
        fade: true,
      });
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
